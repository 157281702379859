<script>

import { subMilliseconds, differenceInMilliseconds, distanceInWordsStrict } from 'date-fns'
import deLocale from 'date-fns/locale/de'
import api from 'api'
import { mapGetters } from 'vuex'
import ChatIdleCountdown from 'molecules/ChatIdleCountdown/ChatIdleCountdown'

export default {
  data: () => ({
    idleTimerId: null,
    idleTimeBuffer: 60500,
    idleTime: null,
    countdownIsOpen: false,
    countdown: null,
    key: 0
  }),

  computed: {
    ...mapGetters(['currentUserChat', 'userInfo', 'idleTimeSeconds']),
    chatNeedsInteraction () {
      if (!this.currentUserChat || this.currentUserChat.status !== 'active') {
        return false
      }
      let messages = [...this.currentUserChat.messages]
      let lastMessage
      while ((lastMessage = messages.pop()) !== undefined) {
        if (lastMessage.userId !== this.userInfo.id) {
          return true
        }
        if (!lastMessage.isDeleted) {
          return false
        }
      }
      return true
    },
    closeAction () {
      if (this.currentUserChat.status === 'pending') {
        return 'cancelChat'
      }
      return 'closeChat'
    }
  },
  watch: {
    chatNeedsInteraction: {
      handler: function (val) {
        if (val) {
          this.startCountdownTimer()
          return
        }
        window.clearTimeout(this.idleTimerId)
      },
      immediate: true
    },
    hasActiveChat: {
      handler: function (val) {
        if (!val) {
          window.clearTimeout(this.idleTimerId)
        }
      }
    }
  },

  // created () {
  //   this.idleTime = this.idleTimeSeconds * 1000
  //   setTimeout(() => {
  //     if (this.chatNeedsInteraction) {
  //       this.startCountdownTimer()
  //     }
  //   }, 1000)
  // },

  // destroyed () {
  //   window.clearTimeout(this.idleTimerId)
  // },

  methods: {
    startCountdownTimer () {
      if (this.idleTime === null || this.countdownIsOpen || (this.$route.name !== 'liveChat' && this.idleTimerId !== null)) {
        return
      }

      let milliSecondsSinceUpdated = differenceInMilliseconds(new Date(), this.currentUserChat.updatedAt)
      this.countdown = this.$createElement(ChatIdleCountdown, {
        key: this.key++,
        props: {
          i18n: this.$i18n,
          idleTime: Math.max(this.idleTime, milliSecondsSinceUpdated),
          idleTimeBuffer: milliSecondsSinceUpdated < this.idleTime ? this.idleTimeBuffer : Math.max(100, this.idleTimeBuffer - milliSecondsSinceUpdated)
        },
        on: {
          'countdown-ended': async () => {
            if (!this.countdownIsOpen || !this.currentUserChat) {
              return
            }
            this.$msgbox.close()
            await api.call(this.closeAction, this.currentUserChat.id)
            this.$alert(this.$root.$t('chat.closedWhileIdle', {
              closingTime: distanceInWordsStrict(subMilliseconds(new Date(), this.idleTime + this.idleTimeBuffer), new Date(), { locale: deLocale }).replace('eine Minute', 'einer Minute')
            })).finally(() => {
              this.$router.push({ name: 'loginLanding' })
            })
          }
        }
      })
      window.clearTimeout(this.idleTimerId)
      this.idleTimerId = window.setTimeout(this.showConfirmation, Math.max(10, this.idleTime - milliSecondsSinceUpdated))
    },

    showConfirmation () {
      if (!this.currentUserChat || this.currentUserChat.status !== 'active' || differenceInMilliseconds(new Date(), this.currentUserChat.updatedAt) < this.idleTime) {
        return
      }
      this.countdownIsOpen = true
      this.$confirm(
        this.countdown,
        this.$t('chat.idleConfirmation.title', { counselor: this.currentUserChat.counselor }),
        {
          confirmButtonText: this.$t('chat.idleConfirmation.ok'),
          cancelButtonText: this.$t('chat.idleConfirmation.cancel'),
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          closeOnHashChange: false
        }
      ).then(() => {
        this.$router.push({ name: 'liveChat' })
        window.clearTimeout(this.idleTimerId)
        this.countdownIsOpen = false
        api.call('updateChat', this.currentUserChat.id).finally(() => { this.$nextTick(this.startCountdownTimer) })
      }).catch(async () => {
        window.clearTimeout(this.idleTimerId)
        this.countdownIsOpen = false
        await api.call(this.closeAction, this.currentUserChat.id)
        this.$router.push({ name: 'loginLanding' })
      })
    }
  },

  render () {
    return ''
  }
}
</script>
